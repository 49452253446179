<template>
  <v-app class="form-container">
    <TextBox
      :cefrLevel="payload.cefr_level"
      v-model="title"
      label="Title"
    />

    <label class="item-label mt-6">Assessment Image</label>
    <div v-if="imageFileLocation" class="mb-2">
      <img
        width="300"
        :src="imageFileLocation"
        alt="Assessment Image" />
    </div>
    <div>
      <label :title="uiTitle" class="es-btn small" :class="{ 'ghost' : status !== 'Draft' }">
        Upload a new image file
        <input
          v-if="status==='Draft'"
          class="d-none"
          type="file"
          accept="image/png, image/jpeg"
          @change="upload($event, 'image')"
        />
      </label>
      <validation-provider rules="required">
        <input type="text" v-model="imageFileLocation" class="d-none" />
      </validation-provider>
    </div>
  </v-app>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import TextBox from "@/components/item/TextBox";
import { v4 as uuidv4 } from "uuid";
export default {
  data: () => ({
    title: "",
    static_text: "Describe the image",

    // Note: The image file is in the data.assets{} payload
    imageFilename: "",
    imageFileLocation: "",

    // Note: The grading image question is in the data.questions[] payload,
    // but the grading image file is the same as the data.assets{} image above.
    gradingImageQuestionId: "",
  }),
  computed: {
    ...mapState("item", ["payload", "isUpdate", "status", "wordLimits"]),
    ...mapGetters("account", ["user"]),
    uiTitle() {
      return this.status === "Draft" ? false : "Upload is only available for Status: Draft."
    }
  },
  methods: {
    ...mapActions("item", ["uploadFile"]),
    async upload(event) {
      const formData = new FormData();
      const file = event.target.files[0];
      if (!file) throw new Error("File did not load sucessfully.");

      formData.append("author_id", this.user.user_id);
      formData.append("file", file);
      formData.append("content_type", file.type);

      try {
        const response = await this.uploadFile(formData);
        const { file_name, file_location } = response.data;
        this.imageFilename = file_name;
        this.imageFileLocation = file_location;
      } catch (error) {
        console.error(error);
      }
    },
    onSubmit() {
      const assets = {
        images: [{
          file_name: this.imageFilename,
          file_location: this.imageFileLocation,
        }],
      }

      const data = {
        template_type: "DESCRIBE_THE_IMAGE",
        title: this.title,
        static_text: this.static_text,
        assets,
      }

      if (!this.isUpdate) {
        /**
         * The questions[] array here is hard-coded because
         *  a) it is designed to be static, except for the peer grading image asset;
         *  b) the API requires it.
         *
         * @note The `assets` object in the QUESTION_WITH_ASSET below is intentionally the same as the `assets` object above.
         */

        // Note: this.wordLimits is available only when creating new items
        const { min, max } = this.wordLimits.advised
        const advisedWordLimitPrompt = `write ${min}—${max} words.`

        data.questions = [
          {
            question_id: uuidv4(),
            question: this.static_text,
            solution: [],
            metadatas: {
              min_word_limit: this.wordLimits.mandatory.min || 0,
              max_word_limit: this.wordLimits.mandatory.max || 0,
              instructions: [
                '', // @note: This empty row is intentional.
                advisedWordLimitPrompt,
              ]
            }
          },
          {
            question_id: uuidv4(),
            question: "Did they describe the image?",
            question_template: "QUESTION_WITH_RESPONSE",
            data_point: "Q_CONTEXTUAL_BINARY",
            position: 0,
            solution: [],
            metadatas: {
              title: "This person is describing an image",
            },
            assets,
          },
          {
            question_id: uuidv4(),
            question: "How well did they do?",
            question_template: "STAR_RATING_WITH_RESPONSE",
            data_point: "RATING_OVERALL_5STAR",
            position: 1,
            solution: [],
            metadatas: {
              title: "This person is describing an image",
            },
            assets,
          },
        ]
      }
      this.$store.commit('item/setPayload', data)
    },
  },
  beforeMount() {
    if (this.isUpdate) {
      const { title } = this.payload
      this.title = title;

      // Assessment image
      const { file_location: imageFileLocation, file_name: imageFilename } = this.payload.assets?.images[0];
      this.imageFileLocation = imageFileLocation;
      this.imageFilename = imageFilename;

      // Grading image
      this.peerGradingQuestion = this.payload.questions.find(({ question_template }) => question_template === "QUESTION_WITH_ASSET");
      this.gradingImageQuestionId = this.peerGradingQuestion?.question_id;
    }
  },
  components: {
    TextBox,
  },
};
</script>

<style lang="scss" scoped>
label {
  color: #264d98;
  &.ghost {
    cursor: not-allowed;
    color: rgba(0, 0, 0, 0.4);
    background-color: rgba(230, 230, 230, 0.4);
    border: none;
  }
}

.form-container {
  max-width: 550pt;
}

::v-deep {
  .v-application--wrap {
    min-height: auto;
  }
}
</style>
